// Web fonts

// Inter font source: https://gwfh.mranftl.com/fonts/inter

// inter-200 - latin
@font-face {
	font-display: swap;
	font-family: "Inter";
	font-style: normal;
	font-weight: 200;
	src: url("../fonts/inter-v18-latin-200.woff2") format("woff2"),
			url("../fonts/inter-v18-latin-200.woff") format("woff"),
			url("../fonts/inter-v18-latin-200.ttf") format("truetype");
}

// inter-regular - latin
@font-face {
	font-display: swap;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/inter-v18-latin-regular.woff2") format("woff2"),
			url("../fonts/inter-v18-latin-regular.woff") format("woff"),
			url("../fonts/inter-v18-latin-regular.ttf") format("truetype");
}

// inter-italic - latin
@font-face {
	font-display: swap;
	font-family: "Inter";
	font-style: italic;
	font-weight: 400;
	src: url("../fonts/inter-v18-latin-italic.woff2") format("woff2"),
			url("../fonts/inter-v18-latin-italic.woff") format("woff"),
			url("../fonts/inter-v18-latin-italic.ttf") format("truetype");
}

// inter-700 - latin
@font-face {
	font-display: swap;
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	src: url("../fonts/inter-v18-latin-700.woff2") format("woff2"),
			url("../fonts/inter-v18-latin-700.woff") format("woff"),
			url("../fonts/inter-v18-latin-700.ttf") format("truetype");
}

// inter-700italic - latin
@font-face {
	font-display: swap;
	font-family: "Inter";
	font-style: italic;
	font-weight: 700;
	src: url("../fonts/inter-v18-latin-700italic.woff2") format("woff2"),
			url("../fonts/inter-v18-latin-700italic.woff") format("woff"),
			url("../fonts/inter-v18-latin-700italic.ttf") format("truetype");
}
