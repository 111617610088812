// Dialog modal
// https://github.com/frend/frend.co/tree/gh-pages/_components/dialogmodal

.fr-dialogmodal--is-ready {
	position:fixed;
	left:0;
	top:0;
	width:100%;
	height:100%;
	z-index:999;
	background:rgba(#000000, .8);

	[role="dialog"] {
		position:absolute;
		left:50%;
		top:50%;
		width:100%;
		max-width:map-get($container-max-widths, xl);
		padding-right:$grid-gutter-width-base / 2;
		padding-left:$grid-gutter-width-base / 2;
		-webkit-transform:translateX(-50%) translateY(-50%); /* Modal blurs when using translate3d */
		transform:translateX(-50%) translateY(-50%); /* Modal blurs when using translate3d */
	}

	[role="document"] {
		padding:$grid-gutter-width-base / 2;
		background:#ffffff;

		h2,
		button {
			margin-bottom:$grid-gutter-width-base;
		}

		h2 {
			float:left;
		}

		button {
			float:right;
			margin-top:.2em;
			@include pwd-fontsize(20);
		}

		.pot-ico-4-close:after {
			position:relative;
			top:-2px;
		}
	}
}

.fr-dialogmodal--is-ready[aria-hidden="true"] {
	visibility:hidden;
}

.fr-dialogmodal--is-ready[aria-hidden="false"] {
	visibility:visible;
}

@media screen and (min-width:#{map-get($grid-breakpoints, lg)}) {

	.fr-dialogmodal--is-ready {
		[role="document"] {
			padding:$grid-gutter-width-base;
		}
	}
}
