// P4 SASS HTML5 Framework 2.0 / Global settings

// == Settings - Bootstrap ==

@import "../bootstrap/variables";

// == Settings - Base ==

// Do not change if you don't know what you're doing!

// Default font size in browsers, in pixels

$base-browser-font-size:16;

// We reset font size to 10px to make it easier to define them

$base-reset-target-size:10;

// == Settings - Typography ==

$base-font-family: Inter, sans-serif;

// This is the default font size we want, in pixels

$base-font-size: 16;

// == Base image directory ==

$base-image-dir:"../../dist/images/";

// == Media queries ==

$base-mq-sm:breakpoint-min(md);

// == Widths ==

$base-content-width: 750px;
$medium-content-width: 1240px;
$wide-content-width: 1440px;

// == Margins ==

$base-margin: 2rem;
$base-margin-1:70px;

// Add media query debug text before body

$media-query-debug:false; // Boolean

// == Settings - Color ==

$black: #0C1115;
$blue: #3475A0;
$blue_2: #66AEC4;
$blue_dark: #445E77;
$blue_darkest: #003558;
$blue_light: #F0F5F8;
$blue_history: #00979E;
$red: #B70000;
$orange: #F77B00;
$green: #4F792D;
$green_bright: #BDCA28;
$grey_light: #EFF1F2;

$theme-color-1:#00979e; // Turquoise
$theme-color-2:#307cae; // Blue
$theme-color-3:#8eb90c; // Green
$theme-color-4:#574794; // Violet
$theme-color-5:#445e77; // Grey blue
$theme-color-6:#bbf1ff; // Light blue
$theme-color-7:#cde1e8; // Light grey blue
$theme-color-8:#c9c9c9; // Light grey

$aboard-theme-color-1: #82A8BF; // Blue
$aboard-theme-color-2: #3475A0; // Blue, darker
$aboard-theme-color-3: #A6C000; // Green
$aboard-theme-color-4: #00979E; // Turquoise
$aboard-theme-color-5: #312E70; // Violet
$aboard-theme-color-6: #618BA5; // Blue, darker
$aboard-theme-color-7: #71AAC8; // Blue, lighter
$aboard-theme-color-8: #757575; // Grey, medium

$base-background-color:#ffffff;
$base-text-color:#333333;

$base-link-color:$theme-color-1;

$base-ruler-color:#cccccc;

$base-row-odd-color:#f7f7f7;
$base-row-even-color:transparent;

$base-signature-text-color:#c0c0c0;

// == Settings - Content ==

$base-content-line-height:(($base-font-size + 8) / $base-font-size);

// == Settings - Text headings ==

// Editor supports headings <h1> to <h5>
// Font sizes are defined in pixels

$heading-font-sizes-xs:
	$base-font-size + 10,
	$base-font-size + 8,
	$base-font-size + 6,
	$base-font-size + 5,
	$base-font-size + 4,
	$base-font-size;

$heading-font-sizes:
	40,
	26,
	24,
	22,
	20,
	18;

$heading-margins:
	0 0 0.7em 0,
	1em 0 0.5em 0,
	1.2em 0 0.5em 0,
	1.2em 0 0.5em 0,
	1.2em 0 0.5em 0;

$heading-color:$base-text-color;
$heading-colors:
	$heading-color,
	$heading-color,
	$heading-color,
	$heading-color,
	$heading-color;

$heading-font-family: $base-font-family;
$heading-font-families:
	$heading-font-family,
	$heading-font-family,
	$heading-font-family,
	$heading-font-family,
	$heading-font-family;

$heading-font-weight:bold;
$heading-font-weights:
	$heading-font-weight,
	$heading-font-weight,
	$heading-font-weight,
	$heading-font-weight,
	$heading-font-weight;

$heading-font-style:normal;
$heading-font-styles:
	$heading-font-style,
	$heading-font-style,
	$heading-font-style,
	$heading-font-style,
	$heading-font-style;

$heading-line-height:1.2;
$heading-line-heights:
	$heading-line-height,
	$heading-line-height,
	$heading-line-height,
	$heading-line-height,
	$heading-line-height;

// == Settings - Buttons & inputs ==

$base-input-text-padding:11px 12px;
$base-input-text-border:1px solid #797979;

$base-button-padding:12px;
$base-button-color:#ffffff;
$base-button-background:#747474;
$base-button-hover-background:darken($base-button-background, 10%);
$base-button-border:0;
