// Styles for custom Plugin 'pwd-custom-ship'

$pwdcp-ship-highlight-color: $theme-color-1 !default;
$pwdcp-ship-heading-color: #575757 !default;

// Common

.pwdcp-ship {
	ul {
		padding-left: 0;
		list-style-type: none;
	}

	li {
		margin-bottom: 5px;
	}

	li:last-child {
		margin-bottom: 0;
	}

	table {
		width: 100%;
	}

	td {
		word-wrap: break-word;
		overflow-wrap: break-word;
	}

	h3 {
		margin-bottom: 1em;
		text-transform: uppercase;
		@include pwd-fontsize(20);
	}

	.side-bar-nav {
		max-width: none;
	}
}

section.pwdcp-ship {
	margin-bottom: $grid-gutter-width-base;
}

// Tables

.pwdcp-ship-table-1 {
	table-layout: fixed;

	&:not(:first-child) thead {
		display: none;
	}

	&:not(:last-child) {
		margin-bottom: 5px;
	}

	th {
		color: $pwdcp-ship-heading-color;
		font-weight: normal;
		text-transform: uppercase;
		@include pwd-fontsize(20);
	}

	.fonts-loaded & th {
		font-weight: bold;
	}
}

.pwdcp-ship-table-1 th,
.pwdcp-ship-table-1 td,
.pwdcp-ship-info-container {
	padding-right: $grid-gutter-width-base / 2;
	padding-bottom: $grid-gutter-width-base / 2;
}

.pwdcp-ship-table-1 td,
.pwdcp-ship-info-container {
	padding-top: $grid-gutter-width-base / 2;
	padding-left: $grid-gutter-width-base / 2;
}

[data-pwdcp-ship-item] {
	border: 1px solid #b4b4b4;
}

[data-pwdcp-ship-item] td {
	background-color: #f3f3f3;
}

[data-pwdcp-ship-item] td:not(:last-child) {
	border-right: 1px solid #b4b4b4;
}

[data-pwdcp-ship-item-info] td {
	padding: 0;
	border-left: 1px solid #b4b4b4;
	border-right: 1px solid #b4b4b4;
	border-bottom: 1px solid #b4b4b4;
}

.pwdcp-ship-column-1 span {
	font-weight: bold;
}

.pwdcp-ship-info-container {
	background-color: #e1eff2;

	.wp-content:not(:last-child) {
		margin-bottom: $grid-gutter-width-base;
	}
}

.pwdcp-ship-list-1 .label,
.pwdcp-ship-name {
	font-weight: bold;
}

// Tabbed content

.pwdcp-ship-tabbed {
	section {
		display: none;
	}

	section h2 {
		display: none;
	}

	.pwdcp-ship-section-selected {
		display: block;
	}
}

// Collapsed content

.pwdcp-ship-content-collapse {
	[data-pwdcp-ship-item] {
		td:not(.pwdcp-ship-table-highlight) {
			@include pwd-transition(background-color 0.3s);
		}

		td:last-child {
			position: relative;
			padding-bottom: 40px;
		}

		button {
			position: absolute;
			right: $grid-gutter-width-base / 2;
			bottom: 10px;
		}
	}

	[data-pwdcp-ship-item-info] {
		display: none;
	}
}

.pwdcp-ship-content-collapse-open {
	[data-pwdcp-ship-item-info] {
		display: block;
	}

	[data-pwdcp-ship-item] {
		td:not(.pwdcp-ship-table-highlight) {
			background-color: $grey_light;
			@include pwd-transition(background-color 0.15s);
		}
	}
}

@media screen {
	// Tables

	[data-pwdcp-ship-item] .pwdcp-ship-table-highlight {
		color: #ffffff;
		background-color: $blue_dark;
	}

	// Collapsed content

	.pwdcp-ship-content-collapse-open {
		[data-pwdcp-ship-item] {
			td:not(:last-child) {
				border-right-color: #ffffff;
			}
		}
	}
}

@media only print, screen and (min-width:#{map-get($grid-breakpoints, sm)}) {
	// Tables

	.pwdcp-ship-info-container .wp-content:not(:last-child) {
		margin-bottom: 0;
	}
}

@media screen and (max-width:#{map-get($grid-breakpoints, md) - 1}) {
	// Responsive tables
	// https://manu.ninja/responsive-tables-for-humans-web-crawlers-and-screen-readers

	.pwdcp-ship {
		thead {
			display: none;
		}

		table,
		tbody,
		tr,
		td {
			display: block;
		}
	}

	.pwdcp-ship-table-1 {
		max-width: 600px;

		td {
			position: relative;
			padding-left: 50%;
			text-align: right;
		}

		td:before {
			position: absolute;
			top: $grid-gutter-width-base / 2;
			left: $grid-gutter-width-base / 2;
			width: 50%;
			color: $pwdcp-ship-heading-color;
			font-weight: bold;
			text-align: left;
			content: attr(data-pwdcp-ship-th);
		}

		.pwdcp-ship-table-highlight:before {
			color: #ffffff;
		}
	}

	[data-pwdcp-ship-item] td:not(:last-child) {
		border-right: 0;
		border-bottom: 1px solid #b4b4b4;
	}

	[data-pwdcp-ship-item-info] td {
		padding: 0;
		text-align: left;

		&:before {
			display: none;
		}
	}
}

@media only print, screen and (min-width:#{map-get($grid-breakpoints, md)}) {
	// Common

	.pwdcp-ship .side-bar-nav {
		text-align: right;
	}

	// Tables

	.pwdcp-ship-list {
		padding-right: 1px;
		// overflow-y:scroll;
	}

	.pwdcp-ship-column-1,
	.pwdcp-ship-column-2 {
		span {
			display: block;
		}
	}

	.pwdcp-ship-column-1,
	.pwdcp-ship-column-2 {
		width: 16%;
		// white-space:nowrap;
	}

	// Collapsed content

	.pwdcp-ship-content-collapse {
		[data-pwdcp-ship-item] {
			td,
			td:last-child {
				padding-bottom: 34px;
			}

			td:last-child,
			button {
				position: static;
			}

			button {
				float: right;
				margin-right: -10px;
				margin-bottom: -24px;
			}
		}
	}

	.pwdcp-ship-content-collapse-open {
		[data-pwdcp-ship-item-info] {
			display: table-row;
		}
	}
}

@media only print, screen and (min-width:#{map-get($grid-breakpoints, lg)}) {
	// Tables

	[data-pwdcp-ship-item] td {
		padding: 30px 20px;
	}

	.pwdcp-ship-info-container {
		padding: 34px 36px;
	}

	.pwdcp-ship-column-1,
	.pwdcp-ship-column-2 {
		width: 12%;
	}
}
