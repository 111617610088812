@import "base/settings";
@import "base/tools";

@import "base/normalize";
@import "bootstrap/bootstrap-grid";
@import "bootstrap/images";
@import "bootstrap/utilities/screenreaders";

@import "components/web_fonts";
@import "components/content";
@import "components/pagination";
@import "components/search";
@import "components/header";

// Common

.pot-some-links {
	margin-bottom: 1em;

	a {
		display: inline-block;
		margin-bottom: 0.5rem;
		margin-right: 0.5rem;

		&:last-child {
			margin-right: 0;
		}
	}

	a:before {
		width: 38px;
		height: 38px;
		background-size: 100%;
	}
}

.pot-ico-2-some {
	position: relative;
	margin-bottom: 7px;
	z-index: 1;
}

.pot-list-1 {
	h2 {
		margin-top: 0;
		margin-bottom: 0.1em;
		@include pwd-heading-style(4, font-size);
	}

	time {
		color: lighten($base-text-color, 40%);
	}
}

.pot-list-1-item:not(:last-child) {
	margin-bottom: $grid-gutter-width-base;
}

// Navigations

.column-nav-content ul,
.pot-contacts-nav {
	font-weight: 700;
}

.column-nav {
	li {
		max-width: 370px + $grid-gutter-width-base;
	}

	img {
		margin-bottom: $grid-gutter-width-base;
	}

	h3 {
		margin-bottom: 0.8em;
	}
}

.column-nav-item {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-content: flex-start;
}

.column-nav-content {
	flex-grow: 2;
}

.column-nav-content ul {
	text-transform: uppercase;
}

.pot-contacts-nav {
	margin-top: 30px;
	@include pwd-fontsize(20);

	a {
		display: inline-block;
		margin-bottom: 5px;
		font-size: 18px;
	}

	a:not(:last-child) {
		margin-right: 10px;
	}
}

// Front page template ('front-page.php')

.pot-fp-section-1 {
	h2,
	time {
		color: $theme-color-1;
	}

	a,
	time {
		display: block;
	}

	a {
		color: $base-text-color;
		line-height: $heading-line-height;
	}

	time {
		font-weight: bold;
	}
}

.pot-fp-section-1-item {
	max-width: 270px;
	border: 1px solid #b4b4b4;
}

.pot-fp-section-1-content {
	padding: $grid-gutter-width-base / 2;
}

section.aboard-news {
	background-color: #a3becf;
	border-bottom: 5px solid white;
	padding-top: 70px;

	.section-header {
		color: white;
		margin-bottom: 35px;
	}

	.header__description {
		text-transform: uppercase;
		display: block;
		@include media-breakpoint-up(md) {
			text-align: right;
		}
	}

	.read-more-col {
		@include media-breakpoint-up(md) {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
		}
	}

	.read-more-aboard {
		font-weight: bold;
		color: #144361;
		&:after {
			font-size: 130%;
			content: " ›";
			speak: none;
			text-decoration: none;
		}
		&:hover {
			text-decoration: none;
			span {
				text-decoration: underline;
			}
		}
	}
}

.pot-fp-section-2-item {
	background-color: white;
	border: 1px solid #b4b4b4;
	.image {
		position: relative;
		img {
			vertical-align: middle;
		}
	}
	.tag-label {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 5px 20px;
		background-color: $theme-color-2;
		color: white;
		&.cat-id-7, //traffic-and-logistics
		&.cat-id-1 {
			//liikenne ja logistiikka
			background-color: $aboard-theme-color-2;
		}
		&.cat-id-509, //environment-and-safety
		&.cat-id-12 {
			//Ympäristö ja turvallisuus
			background-color: $aboard-theme-color-3;
		}
		&.cat-id-507, //blog
		&.cat-id-14 {
			//puheenvuoro
			background-color: $aboard-theme-color-8;
		}

		&.cat-id-513, //today
		&.cat-id-16 {
			//ajassa
			background-color: $aboard-theme-color-4;
		}

		&.cat-id-511, //industry-professional
		&.cat-id-18 {
			//alan ammattilainen
			background-color: $aboard-theme-color-7;
		}

		&.cat-id-505, //through-the-port
		&.cat-id-20 {
			//sataman kautta
			background-color: $aboard-theme-color-5;
		}
	}
	a {
		font-weight: bold;
		color: $base-text-color;
	}
}

.pot-fp-section-2-content {
	padding: $grid-gutter-width-base / 2;
}

.pot-tagline-bottom {
	margin-bottom: $grid-gutter-width-base;
	text-transform: uppercase;

	h2 {
		font-style: italic;
		line-height: 1.1;
		text-align: center;
	}
}

// Default page view

.pot-sidebar-content {
	padding: 15px;
	border: 2px solid #000000;
}

// Default posts view

.wp-posts-list .wp-post {
	margin-bottom: $grid-gutter-width-base;

	a {
		display: block;
		font-weight: bold;
		line-height: $heading-line-height;
		@include pwd-fontsize($base-font-size + 4);
	}
}

.wp-post-entry-summary > *:last-child {
	margin-bottom: 0;
}

// Single post view

.posts-parent-navigation {
	margin-top: 30px;
}

// Contacts view

.pot-contacts-list-2,
.pot-sidebar-contacts {
	> *:not(:last-child) {
		margin-bottom: 30px;
	}
}

.pot-contacts-list-1 .pot-contacts-list-1-item {
	max-width: 270px + $grid-gutter-width-base;
}

// 'maps.php' template

.pot-map-links,
.pot-map button {
	font-weight: bold;
	text-transform: uppercase;
}

.pot-map button {
	color: $base-link-color;
	line-height: inherit;
	background: transparent;
}

.pot-map-links {
	margin-top: $grid-gutter-width-base / 2;

	button,
	a:not(:last-child) {
		margin-right: 15px;
	}
}

// 404 page

.pot-404-code {
	font-weight: bold;
	color: lighten($base-text-color, 30%);
}

@media screen {
	// Icons

	.pot-ico-2:before,
	.pot-ico-3:after,
	.side-bar-nav-2 a:after {
		background-image: url("#{$base-image-dir}pot-icons-sprite.png?3");

		.svg & {
			background-image: url("#{$base-image-dir}pot-icons-sprite_3.svg?3");
		}
	}

	.pot-ico-1:before {
		background: transparent url("#{$base-image-dir}pot-contact-icons-sprite.png?2") no-repeat center top;

		.svg & {
			background-image: url("#{$base-image-dir}pot-contact-icons-sprite.svg?2");
		}
	}

	.pot-ico-4:before,
	.pot-ico-4-close:after {
		background: transparent url("#{$base-image-dir}pot-map-icons-sprite.png?2") no-repeat center top;

		.svg & {
			background-image: url("#{$base-image-dir}pot-map-icons-sprite.svg?2");
		}
	}

	.pot-ico-5:before {
		background: transparent url("#{$base-image-dir}pwd-weather-icons-sprite.png?2") no-repeat center top;

		.svg & {
			background-image: url("#{$base-image-dir}pwd-weather-icons-sprite.svg?2");
		}
	}

	.pot-ico-2-earth:before {
		background-position: center -29px;
	}

	.pot-ico-2-search:before {
		background-position: center -129px;
	}

	.pot-ico-2-linkedin:before {
		background-position: center -170px;
	}

	.pot-ico-2-twitter:before {
		background-position: center -212px;
	}

	.pot-ico-2-instagram:before {
		background-position: center -295px;
	}

	.pot-ico-2-youtube:before {
		background-position: center -254px;
	}

	.pot-ico-3-arrow-down:after {
		background-position: center -54px;
	}

	.pot-ico-3-arrow-up:after {
		background-position: center -66px;
	}

	.pot-ico-1-mail:before {
		background-position: center -19px;
	}

	.pot-ico-1-fax:before {
		background-position: center -40px;
	}

	.pot-ico-1-earth:before {
		background-position: center -58px;
	}

	.pot-ico-4-zoom:before {
		background-position: center 0;
	}

	.pot-ico-4-map:before {
		background-position: center -18px;
	}

	.pot-ico-4-pin:before {
		width: 12px;
		background-position: center -36px;
	}

	.pot-ico-4-close {
		&:before {
			display: none;
		}

		&:after {
			display: inline-block;
			width: 29px;
			height: 29px;
			margin-left: 8px;
			content: "";
			vertical-align: middle;
			background-position: center -59px;
		}
	}

	.pot-ico-5-temp:before {
		width: 14px;
		background-position: center -86px;
	}

	.pot-ico-5-wind:before {
		width: 28px;
		background-position: center -117px;
	}

	.pot-ico-5-waterlevel:before {
		background-position: center -146px;
	}

	.pot-ico-5-watertemp:before {
		background-position: center -215px;
	}

	.pot-hero-1,
	.pot-language-nav button[aria-expanded="true"],
	.pot-language-nav button:focus,
	.pot-language-nav button:hover {
		.pot-ico-2-earth:before {
			background-position: center -79px;
		}

		.pot-ico-3-arrow-down:after {
			background-position: center -104px;
		}

		.pot-ico-3-arrow-up:after {
			background-position: center -116px;
		}
	}

	.pot-hero-1 {
		.pot-ico-5-temp:before {
			background-position: center 1px;
		}

		.pot-ico-5-wind:before {
			background-position: center -28px;
		}

		.pot-ico-5-waterlevel:before {
			background-position: center -57px;
		}

		.pot-ico-5-watertemp:before {
			background-position: center -180px;
		}
	}

	.pot-hero-1 .pot-ico-2-search,
	button.pot-ico-2-search,
	.pot-search a:hover,
	.pot-search a:focus {
		&:before {
			background-position: center -155px;
		}
	}

	.pot-hero-1 {
		.pot-search a:hover,
		.pot-search a:focus {
			&:before {
				background-position: center -129px;
			}
		}

		.pot-language-nav button[aria-expanded="true"],
		.pot-language-nav button:hover {
			.pot-ico-2-earth:before {
				background-position: center -29px;
			}

			.pot-ico-3-arrow-down:after {
				background-position: center -54px;
			}

			.pot-ico-3-arrow-up:after {
				background-position: center -66px;
			}
		}
	}

	// Common

	.side-bar-nav-2 a:after {
		background-color: $base-background-color;
		background-position: center 0;
		@include pwd-border-radius(50%, 50%, 50%, 50%);
	}

	.pot-button-1,
	.pot-button-1:before {
		color: #ffffff;
	}

	.pot-button-1 {
		border: 0;
		background-color: $blue_dark;

		&:hover {
			background-color: lighten($blue_dark, 10%);
		}
	}

	.pot-bg-5 .pot-ico-2-some:after {
		background-color: #ffffff;
	}

	.pot-hero-1-bottom,
	.pot-hero-1-nav {
		background: transparent url("#{$base-image-dir}bg-000000-55.png") repeat;
		background: rgba(#000000, 0.7);
	}

	// Template header

	.top-bar-logo {
		position: relative;

		&:after {
			position: absolute;
			left: 0;
			bottom: 5px;
			width: 100%;
			height: 4px;
			content: "";
			speak: none;
			background-color: #b1b1b1;
			opacity: 0;
			transition: opacity 0.3s;
		}

		&:hover:after,
		&:focus:after {
			opacity: 1;
			transition-duration: 0.15s;
		}
	}

	.pot-hero-1 .pot-top-bar {
		background: transparent url("#{$base-image-dir}bg-000000-55.png") repeat;
		background: rgba(#000000, 0.55);
	}

	// Navigations

	.pot-search a,
	.pot-language-drop button {
		color: $black;
		transition: color 0.3s, background-color 0.3s;

		&:hover,
		&:focus {
			transition-duration: 0.15s;
		}
	}

	.pot-search a:hover,
	.pot-search a:focus,
	.pot-language-nav button:hover,
	.pot-language-nav button:focus,
	.pot-language-nav button[aria-expanded="true"] {
		color: $white;
		background-color: $black;
	}

	.pot-top-bar-default-nav a {
		transition: color 0.3s;

		&:hover,
		&:focus {
			transition-duration: 0.15s;
		}
	}

	.pot-language-drop {
		position: relative;

		.pot-ico-3:after {
			display: inline-block;
		}

		button {
			cursor: pointer;
			border-radius: 0;
		}
	}

	.pot-language-drop {
		ul {
			position: absolute;
			top: 100%;
			left: -1px;
			right: -1px;
			padding-bottom: 10px;
			text-align: right;
			background: $black;
			z-index: 100;
			border-top-width: 0;
			border-left-style: solid;
			border-right-style: solid;
			border-bottom-style: solid;
		}

		li {
			display: block;
			padding: 0;
		}

		li:not(:last-child) {
			margin: 0;
		}

		a,
		a:hover {
			color: #ffffff;
		}
	}

	.pot-hero-1 {
		.pot-language-drop ul {
			background-color: #ffffff;
		}

		.pot-search a,
		.pot-language-nav button {
			background-color: rgba(#ffffff, 0);
		}

		.pot-search a:hover,
		.pot-search a:focus,
		.pot-language-nav button:hover,
		.pot-language-nav button:focus,
		.pot-language-nav button[aria-expanded="true"] {
			color: #000000;
			background-color: rgba(#ffffff, 1);
		}

		.pot-language-drop a {
			color: $base-text-color;
		}
	}

	.sub-nav {
		a {
			transition: background-color 0.3s;
		}

		a:hover,
		a:focus {
			transition-duration: 0.15s;
		}
	}

	.side-bar-nav {
		a {
			transition: color 0.3s, background-color 0.3s;
		}

		a:hover,
		a:focus {
			transition-duration: 0.15s;
		}
	}

	.pot-icon-nav {
		a {
			position: relative;
			padding-bottom: 5px;
			@include pwd-text-shadow(0, 0, 6, #000000);
		}

		a:after {
			position: absolute;
			left: 0;
			bottom: -3px;
			width: 100%;
			content: "";
			speak: none;
			height: 4px;
			opacity: 0;
			background-color: #ffffff;
			transition: opacity 0.3s;
		}

		a:hover,
		a:focus {
			text-decoration: none;
		}

		a:hover:after {
			opacity: 1;
			transition-duration: 0.15s;
		}
	}

	.pot-bg-6 {
		.column-nav {
			img {
				margin-bottom: 0;
			}
		}

		.column-nav-content {
			padding: $grid-gutter-width-base / 2;
			background-color: #ffffff;
		}
	}

	// Front page

	.pot-tagline-bottom {
		margin-bottom: 0;
		padding-top: $grid-gutter-width-base;
		padding-bottom: $grid-gutter-width-base;
	}

	// Default page view

	.pot-sidebar-content {
		padding: 15px;
		border: 0;
	}

	// 'maps.php' template

	.pot-map button {
		padding: 0;
	}
}

@media screen and (min-width:#{map-get($grid-breakpoints, sm)}) {
	.pot-hero-1-bottom {
		background: transparent;
	}
}

@media only print, screen and (min-width:#{map-get($grid-breakpoints, sm)}) {
	// Front page template ('front-page.php')

	.pot-fp-section-1-column {
		display: flex;

		> div {
			width: 100%;
		}
	}

	.pot-tagline-bottom h2 {
		max-width: 8em;
		margin-left: auto;
		margin-right: auto;
		@include pwd-heading-style(1, font-size);
	}
}

@media only print, screen and (min-width:#{map-get($grid-breakpoints, md)}) {
	// Navigations

	.column-nav {
		h3 {
			@include pwd-fontsize(30);
		}
	}

	// Navigations

	.pot-contacts-nav {
		margin-top: 40px;

		a {
			margin-right: 20px;
		}
	}

	// Front page template ('front-page.php')

	.pot-fp-section-1 {
		h2 {
			margin-bottom: 1.5em;
			@include pwd-fontsize(40);
		}

		picture,
		time {
			margin-bottom: 14px;
		}
	}

	.pot-fp-section-1-item {
		padding-bottom: 25px;
	}

	.pot-tagline-bottom h2 {
		@include pwd-fontsize(55);
	}
}

@media screen and (min-width:#{map-get($grid-breakpoints, lg)}) {
	// Navigations

	.pot-hero-1-nav {
		padding-top: $grid-gutter-width-base / 2 + 5;
		padding-bottom: $grid-gutter-width-base / 2 - 5px;
	}

	.pot-bg-6 .column-nav-content {
		padding: 30px 25px;
	}

	// Front page template ('front-page.php')

	.pot-tagline-bottom {
		padding-top: 45px;
		padding-bottom: 45px;
	}

	// Default page view

	.pot-sidebar-content {
		padding: 40px 36px;
	}
}

@import "components/choices";
@import "components/dialogmodal";
@import "components/pwd-custom-ship";
