// Pagination with WP 'the_posts_pagination' function

.pagination,
.posts-navigation {
	margin-bottom:1em;
	padding-top:$grid-gutter-width-base;
	border-top:1px solid #c9c9c9;

	a:before,
	a:after {
		speak:none;
	}

	.prev,
	.nav-previous a {
		&:before {
			content:"← ";
		}
	}

	.next,
	.nav-next a {
		&:after {
			content:" →";
		}
	}
}

.pagination {
	.nav-links > * {
		display:inline-block;
		margin-bottom:5px;
	}

	.prev {
		margin-right:15px;
	}

	.next {
		margin-left:15px;
	}
}

.posts-navigation .nav-links > *:not(:last-child) {
	margin-bottom:1em;
}

@media only print, screen and (min-width:#{map-get($grid-breakpoints, sm)}) {

	.posts-navigation {
		.nav-links {
			display:flex;

			> *:not(:last-child) {
				margin-bottom:0;
			}
		}

		.nav-previous {
			width:50%;
		}

		.nav-next {
			width:100%;
			text-align:right;
		}

		.nav-previous + .nav-next {
			width:50%;
		}
	}
}
