@import "../base/settings";
@import "../base/tools";


/**
 *	Styles for both mobile & desktop
 */
 .header {
	margin: 0 auto;
	padding: 0;
	max-width: $wide-content-width;

	&__lower-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1.5rem 2rem;
	}

	&__logo {
		display: flex;
		align-items: center;
		max-width: 270px;
		width: 100%;
		z-index: 1;

		@include media-breakpoint-down(s) {
			max-width: 150px;
		}

		img {
			width: 100%;
		}
	}
}

/**
 *	Mobile/Desktop specific styles
 * You can use both, or only one of the options. If only one is used, remove media querys and just import the one you need (and delete unused .scss file)
 * Delete unneeded template part in header.php too if only one is used
 */
// mobile
@include media-breakpoint-down(md) {
	@import "header-mobile";
}

// desktop
@include media-breakpoint-up(lg) {
	@import "header-desktop";
}
