/**
 *	Navigation, desktop
 */
 #toggle-nav-menu, .nav-container-mobile {
	display: none;
}

// header div
.header {
	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem 2rem;
	}

	&__top-row {
		display: flex;
		border-bottom: 1px dashed $black;
	}

	&__lower-row {
	}

	&__quick-links-left, &__quick-links-right {
		display: flex;
		align-items: center;
		padding-left: 2.5rem;
		padding-right: 2.5rem;

		a {
			@include link-with-inline-svg;

			svg {
				width: 2.2rem;
				height: 2.2rem;
			}
		}
	}

	&__quick-links-left {
		flex-grow: 1;

		a {
			margin-right: 3rem;
		}
	}
}

// langs
.pot-language-nav {
	border-left: 1px dashed $black;
	border-right: 1px dashed $black;

	button {
		padding: 2rem;
	}

	.pot-button-title-faux {
		font-size: 1.4rem;
		letter-spacing: .75px;
	}

	.pot-language-drop a {
		letter-spacing: .75px;
	}
}

// Search
.search-link {
	svg {
		width: 3rem;
		height: 3rem;
	}
}

// nav container div
.nav-container-desktop {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: $medium-content-width;
	margin: auto;
	padding: 1rem 4rem;
	width: 100%;
}

// wp nav menu
.nav-main {
	width: 100%;
}

.nav-main__list {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.nav-main__item {
	display: flex;
	align-items: center;
	border-bottom: 1px solid transparent;

	a {
		color: $black;
		text-decoration: none;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 1px;
		padding-top: .5rem;
		padding-bottom: .5rem;
		// border-bottom: 3px solid transparent;
		text-transform: none;

		&:hover, &:focus, &:active {
			// border-bottom: 3px solid $black;
			text-decoration: underline;
			color: $blue;
		}
	}

	&--has-children {
		justify-content: center;
		margin: 0 1rem;
		position: relative;

		& > a {
			text-transform: uppercase;
			padding: 1.5rem;

			&.active-boi {
				background: $white;
				box-shadow: -.5rem -.5rem .5rem rgba($black, .1), .5rem -.5rem .5rem rgba($black, .1);
				z-index: 3;
			}
		}

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.nav-main--active.nav-main__item > a {
	text-decoration: underline;
	color: $blue;
}

.nav-main__sub-menu {
	position: absolute;
	top: 100%;
	width: 100%;
	left: 0;
	z-index: 2;
	background: $white;
	padding: 0;
	margin-top: 0;
	box-shadow: 0 0 1.5rem rgba($black, .3);

	.nav-main__item {
		border-bottom: 1px dashed $black;

		&:last-child {
			border-bottom: none;
		}

		a {
			width: 100%;
			min-width: 12rem;
			padding: 2rem;
			color: $black;
			border: none;

			&:hover, &:focus, &:active {
				// background: rgba($black, .2);
				text-decoration: underline;
				color: $blue;
				border: none;
			}
		}
	}
}


// Submenu logic
.toggle-submenu {
	display: block;
	background: $white;
	border-radius: 100%;
	width: 3rem;
	min-width: 3rem;
	height: 3rem;
	padding: 0;
	margin-left: 1rem;
	cursor: pointer;

	& > * {
		pointer-events: none;
	}

	&:hover, &:focus, &:active {
		background: none;
	}

	svg {
		width: 100%;
		height: 100%;
		transition: .2s ease-in-out;
		transform: translateX(4px);
	}

	&--opened {
		svg {
			transform: translateX(-4px) rotate(180deg);
		}
	}
}

.nav-main__item--has-children {

	// submenus hidden by default, visible with --visible modifier
	.nav-main__sub-menu {
		display: none;

		&--visible {
			display: block;
		}
	}
}
