// P4 SASS HTML5 Framework / Mixins & functions

@import "_settings.scss";
@import "../bootstrap/bootstrap-grid";

// Get base font size, set to <html> element
// By default we use relative size from browser default (16px)
// and target is is specified in $reset-target-size

@mixin pwd-base-fontsize() {

	$font-size:percentage($base-reset-target-size/$base-browser-font-size);

	font-size:$font-size;
	-webkit-text-size-adjust:$font-size;
	-ms-text-size-adjust:$font-size;
}

// Get relative font size (rem) from px value
// Provides px fallback to older browsers
// If you want just pixel size, set $fixed to true
// http://snook.ca/archives/html_and_css/font-size-with-rem

@mixin pwd-fontsize($px:$base-font-size, $fixed:false, $xs:false, $sm:false, $md:false, $lg:false) {

	// Fallback to REM units and/or fixed size

	font-size:$px * 1px;

	@if $fixed == false {
		font-size:$px / $base-reset-target-size * 1rem;
	}

	@if $xs {
		@media #{$base-mq-xs} {
			font-size:$xs * 1px;
			@if $fixed == false {
				font-size:$xs / $base-reset-target-size * 1rem;
			}
		}
	}

	@if $sm {
		@media #{$base-mq-sm} {
			font-size:$sm * 1px;
			@if $fixed == false {
				font-size:$sm / $base-reset-target-size * 1rem;
			}
		}
	}

	@if $md {
		@media #{$base-mq-md} {
			font-size:$md * 1px;
			@if $fixed == false {
				font-size:$md / $base-reset-target-size * 1rem;
			}
		}
	}

	@if $lg {
		@media #{$base-mq-lg} {
			font-size:$lg * 1px;
			@if $fixed == false {
				font-size:$lg / $base-reset-target-size * 1rem;
			}
		}
	}
}

@mixin link-with-inline-svg {
	display: flex;
	align-items: center;
	color: $black;
	font-size: 1.4rem;
	text-transform: uppercase;
	letter-spacing: 0.75px;

	svg {
		width: 1.8rem;
		height: 1.8rem;
		margin-right: 1rem;
	}
}

// Micro clearfix
// http://nicolasgallagher.com/micro-clearfix-hack/

@mixin pwd-clearfix {

	&:before {
		content:"";
		display:table;
	}

	&:after {
		content:"";
		display:table;
		clear:both;
	}
}

// Buttons
// Unified and reusable appearance for <button>, <input type="submit"> and <a> elements.

// $color 				Text color
// $background 			Background color
// $hover-background 	Background hover color

@mixin pwd-base-button($color:$base-button-color, $background:$base-button-background, $hover-background:$base-button-hover-background) {

	width:auto !important;
	margin:0;
	padding:$base-button-padding;
	color:$color;
	text-decoration:none;
	line-height:normal;
	vertical-align:baseline;
	border:$base-button-border;
	background:$background;
	overflow:visible; // Address `overflow` set to `hidden` in IE 8/9/10/11
	-webkit-appearance:button; // Correct inability to style clickable `input` types in iOS.
	@include pwd-fontsize($base-font-size);

	&:hover {
		background:$hover-background;
	}

    // Removes inner padding and border in FF3+
	// www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
	&::-moz-focus-inner {
		border:0;
		padding:0;
	}
}

// Text inputs
// Unified and reusable appearance for any text like inputs

// $padding 	Input padding
// $border 		Input border

@mixin pwd-base-text-input($padding:$base-input-text-padding, $border:$base-input-text-border) {

	margin:0;
	padding:$padding;
	line-height:normal;
	vertical-align:baseline;
	border:$border;
	@include pwd-fontsize($base-font-size);

	// Removes inner padding and border in FF3+
	// www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
	&::-moz-focus-inner {
		border:0;
		padding:0;
	}

	&:focus {
		outline:none;
	}
}

// Font face
// Generates font-face rule for EOT, WOFF, TTF and SVG web fonts

// Note the hash index after .svg; You'll check your .svg files to make sure that they match this.
// Just use a text editor and search for ID and change that x to match $svg-hash.

// $font-family		Font family name (string)
// $font-filename	Font filename (string). All formats are mandatory and have to have same name.
// $font-weight		Font weight (string) (optional). Provide the correct weight for font.
// $font-style		Font style (string) (optional). Provide the correct style for font.

// https://developer.mozilla.org/en/CSS/@font-face
// http://www.fontspring.com/blog/further-hardening-of-the-bulletproof-syntax

@mixin pwd-font-face($font-family, $font-filename, $font-weight:normal, $font-style:normal, $svg-hash:$font-filename) {

	$font-file:"fonts/" + $font-family + "/" + $font-filename;

	font-family:$font-family;
	src:url($font-file + ".eot"); // IE9 Compat Modes
	src:url($font-file + ".eot?#iefix") format("embedded-opentype"), // IE6-IE8
		url($font-file + ".woff") format("woff"),
		url($font-file + ".ttf") format("truetype"),
		url($font-file + ".svg#" + $svg-hash) format("svg");
	font-weight:$font-weight;
	font-style:$font-style;
}

// Text heading styles
// Helps to normalize heading styles according to settings in _settings.scss

// $heading-level		Heading level <h1> - <h3> (int)
// $margin				Heading margin (mixxed)
// $color				Heading font color (mixxed)
// $font-family			Heading font family name (string)
// $font-size 			Heading font size in pixels (int)
// $font-weight			Heading font weight (string / int)
// $font-style			Heading font style (string)
// $font-line-height	Heading line height. Use any units you want. (string)
// $common-styles		Output only common styles for headings. (boolean)
// $reset-styles		Reset element style to heading style. (boolean)

@mixin pwd-heading-styles($heading-level:false, $margin:false, $color:false, $font-family:false, $font-size:false, $font-weight:false, $font-style:false, $line-height:false, $common-styles:false, $reset-styles:false) {

	// Output common or reset non heading element styles

	@if $common-styles or $reset-styles {

		@if $reset-styles and $heading-level and $margin == false {
			margin:nth($heading-margins, $heading-level);
		}

		@if $reset-styles and $heading-level and $margin {
			margin:$margin;
		}

		@if $color != $base-text-color or $reset-styles {
			color:$heading-color;
		}

		@if $font-family != $base-font-family or $reset-styles {
			font-family:$heading-font-family;
		}

		@if $reset-styles and $heading-level {
			@include pwd-fontsize(nth($heading-font-sizes, $heading-level));
		}

		font-weight:$heading-font-weight;
		font-style:$heading-font-style;
		line-height:$heading-line-height;

	}

	// Output individual styles for supported headings
	// All common properties between heading styles will be skipped
	// to avoid unnecessary bloat

	@if $heading-level and $reset-styles == false {

		@if $margin == false {
			margin:nth($heading-margins, $heading-level);
		}
		@else {
			margin:$margin;
		}

		@if $color == false and nth($heading-colors, $heading-level) != $base-text-color {
			color:nth($heading-colors, $heading-level);
		}
		@else if $color {
			color:$color;
		}

		@if $font-family == false {
			font-family:nth($heading-font-families, $heading-level);
		}
		@else if $font-family {
			font-family:$font-family;
		}

		@if $font-size == false {
			@include pwd-fontsize(nth($heading-font-sizes, $heading-level));
		}
		@else {
			@include pwd-fontsize($font-size);
		}

		@if $font-weight == false and nth($heading-font-weights, $heading-level) != $heading-font-weight {
			font-weight:nth($heading-font-weights, $heading-level);
		}

		@else if $font-weight {
			font-weight:$font-weight;
		}

		@if $font-style == false and nth($heading-font-styles, $heading-level) != $heading-font-style {
			font-style:nth($heading-font-styles, $heading-level);
		}
		@else if $font-style {
			font-style:$font-style;
		}

		@if $line-height == false and nth($heading-line-heights, $heading-level) != $heading-line-height {
			line-height:nth($heading-line-heights, $heading-level);
		}

		@else if $line-height {
			line-height:$line-height;
		}

	}
}

// Text heading style
// Gets individual text heading style
// Supported properties: margin, color, font-family, font-size, font-weight, font-style, line-height

// $heading-level		Heading level (int)
// $property			CSS property (string)

@mixin pwd-heading-style($heading-level:false, $property:false) {

	@if $heading-level and $property {

		@if $property == margin {
			margin:nth($heading-margins, $heading-level);
		}

		@if $property == color {
			color:nth($heading-colors, $heading-level);
		}

		@if $property == font-family {
			font-family:nth($heading-font-families, $heading-level);
		}

		@if $property == font-size {
			@include pwd-fontsize(nth($heading-font-sizes, $heading-level));
		}

		@if $property == font-weight {
			font-weight:nth($heading-font-weights, $heading-level);
		}

		@if $property == font-style {
			font-style:nth($heading-font-styles, $heading-level);
		}

		@if $property == line-height {
			line-height:nth($heading-line-heights, $heading-level);
		}

	}
}

// CSS Linear gradient from top to bottom and without color stops

// IE 10+, FF 3.6+, Chrome 4+, Safari 4.0+, Opera 11.1+, iOS 3.2+, Android 2.1+
// http://caniuse.com/#feat=css-gradients

// $color-top 		Top color in HEX format
// $color-bottom	Bottom color in HEX format
// $ie-gradient		Provides MS filter gradient for IE 6-9

// https://developer.mozilla.org/en-US/docs/CSS/linear-gradient

@mixin pwd-linear-gradient($color-top, $color-bottom, $ie-gradient:false) {

	@if $ie-gradient == false {
		background:$color-bottom; //* Old browsers */
		background:-moz-linear-gradient(top, $color-top 0%, $color-bottom 100%); //* FF3.6+ */
		background:-webkit-gradient(linear, left top, left bottom, color-stop(0%, $color-top), color-stop(100%, $color-bottom)); //* Chrome,Safari4+ */
		background:-webkit-linear-gradient(top, $color-top 0%, $color-bottom 100%); //* Chrome10+,Safari5.1+ */
		background:-o-linear-gradient(top, $color-top 0%, $color-bottom 100%); //* Opera 11.10+ */
		background:-ms-linear-gradient(top, $color-top 0%, $color-bottom 100%); //* IE10+ */
		background:linear-gradient(to bottom, $color-top 0%, $color-bottom 100%); //* W3C */
	} @else {
		filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#{$color-top}", endColorstr="#{$color-bottom}", GradientType=0); //* IE6-9 */
	}

}

// CSS Border radius

// IE 9+, FF 2+, Chrome 4+, Safari 3.1+, Opera 10.5+, iOS 3.2+, Android 2.1+
// http://caniuse.com/#feat=border-radius

// [ <length> | <percentage> ]{1,4}  [ / [ <length> | <percentage> ]{1,4}]
// https://developer.mozilla.org/en/CSS/border-radius

@mixin pwd-border-radius($top-left:5px, $top-right:5px, $bottom-left:5px, $bottom-right:5px) {

	-webkit-border-radius:$top-left $top-right $bottom-left $bottom-right;
	-moz-border-radius:$top-left $top-right $bottom-left $bottom-right;
	border-radius:$top-left $top-right $bottom-left $bottom-right;

}

// CSS Transform

// IE 9+, FF 3.5+, Chrome 4+, Safari 3.1+, Opera 10.5+, iOS 3.2+, Android 2.1+, IE Mobile 10+
// http://caniuse.com/#feat=transforms2d

// Formal syntax: none | <transform-function>
// https://developer.mozilla.org/en-US/docs/Web/CSS/transform

@mixin pwd-transform($property) {

	-webkit-transform:$property;
	-moz-transform:$property;
	-ms-transform:$property;
	-o-transform:$property;
	transform:$property;

}

// CSS Transition

// IE 10+, FF 4+, Chrome 4+, Safari 3.1+, Opera 10.5+, iOS 3.2+, Android 2.1+
// http://caniuse.com/#feat=css-transitions

// [ <transition-property> || <transition-duration> || <transition-timing-function> || <transition-delay> ]
// https://developer.mozilla.org/en/CSS/transition
// https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Using_CSS_transitions

// $properties	List of properties, separated by comma

@mixin pwd-transition($properties...) {

	-webkit-transition:$properties;
	-moz-transition:$properties;
	-ms-transition:$properties;
	-o-transition:$properties;
	transition:$properties;

	-webkit-backface-visibility:hidden; // This here prevents chrome from flickering
}

// CSS Box shadow

// IE 9+, FF 3.5+, Chrome 4+, Safari 3.1+, Opera 10.5+, iOS 4+, Android 2.1+
// http://caniuse.com/#feat=css-boxshadow

// $x 	X offset (px)
// $y 	Y offset (px)
// $blur 	Blur radius (px)
// $spread 	Spread radius (px)
// $color 	Shadow color in RGB, RGBA or HEX format. IE polyfill doesn't support alpha channel.
// $inset 	Places shadow inside the element. Set value to "inset" (optional)
// $ie-shadow	Polyfill for IE6-8, requires PIE (boolean)

// inset? && [ <offset-x> <offset-y> <blur-radius>? <spread-radius>? <color>? ]
// https://developer.mozilla.org/en-US/docs/CSS/box-shadow

@mixin pwd-box-shadow ($x:1, $y:1, $blur:1, $spread:1, $color:#000000, $inset:false, $ie-shadow:false) {

	@if $ie-shadow == false {

		$prefixes: "-moz", "-webkit";

		@if $inset {
			@each $prefix in $prefixes {
				#{$prefix}-box-shadow:inset #{$x}px #{$y}px #{$blur}px #{$spread}px $color;
			}
			box-shadow:inset #{$x}px #{$y}px #{$blur}px #{$spread}px $color;
		} @else {
			@each $prefix in $prefixes {
				#{$prefix}-box-shadow:#{$x}px #{$y}px #{$blur}px #{$spread}px $color;
			}
			box-shadow:#{$x}px #{$y}px #{$blur}px #{$spread}px $color;
		}
	} @else {
		-pie-box-shadow:#{$x}px #{$y}px #{$blur}px #{$spread}px $color;
		behavior:url("js/PIE.php");
	}
}

// CSS Text shadow

// IE 10, FF 3.5+, Chrome 4+, Safari 4+, Opera 9.5+, iOS 3.2+, Android 2.1+
// http://caniuse.com/#feat=css-textshadow

// $x 			X offset (px)
// $y 			Y offset (px)
// $blur 		Blur radius (px). IE filter doesn't support this.
// $color 		Shadow color in RGB, RGBA or HEX format. IE filter doesn't support alpha channel.
// $ie-shadow	IE filter which looks hideous. Use at your own risk. (boolean)

// <color>? <offset-x> <offset-y> <blur-radius>? | <offset-x> <offset-y> <blur-radius>? <color>?
// https://developer.mozilla.org/en-US/docs/CSS/text-shadow

@mixin pwd-text-shadow($x:1, $y:1, $blur:1, $color:#cccccc, $ie-shadow:false) {

	@if $ie-shadow == false {
		text-shadow:#{$x}px #{$y}px #{$blur}px $color;
	} @else {
		filter:dropshadow(color=#{$color}, offx=#{$x}, offy=#{$y});
		zoom:1;
	}
}

// Hide text
// http://nicolasgallagher.com/another-css-image-replacement-technique/

@mixin pwd-hide-text {

	color:transparent;
	font:0/0 a;
	text-shadow:none;

}

// List for navigation

@mixin pwd-navigation-list {

	margin:0;
	padding:0;
	list-style-type:none;
	overflow:hidden;

	> li {
		display:block;
		float:left;

		&:last-child {
			margin-right:0;
		}
	}
}

// Opacity

@mixin pwd-opacity($value:0.5) {

	opacity:$value;
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$value*100})";
	filter:alpha(opacity=$value * 100);
	zoom:1;

}

// CSS3 PIE
// CSS3 polyfill for IE 6-9
// http://css3pie.com/

@mixin pwd-pie() {

	position:relative;
	behavior:url(js/PIE.htc);

}

// Cross browser box model alternation with polyfill for IE

// https://github.com/Schepp/box-sizing-polyfill
// https://developer.mozilla.org/en-US/docs/Web/CSS/box-sizing

@mixin pwd-border-box() {

	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	*behavior:url(js/boxsizing.htc); // IE6 & 7

}

// Simple breakpoint mixin

@mixin pwd-breakpoint($screen) {

	@if $screen == xs {
		@media (min-width: #{$screen-xs-min}) {
			@content;
		}
	} @else if $screen == sm {
		@media (min-width: #{$screen-tablet}) {
			@content;
		}
	} @else if $screen == md {
		@media (min-width: #{$screen-desktop}) {
			@content;
		}
	} @else if $screen == lg {
		@media (min-width: #{$screen-lg-desktop}) {
			@content;
		}
	} @else {
		@warn "Breakpoint mixin supports: xs, sm, md, lg";
	}
}

@mixin contact-info-styles {
	display: flex;
	margin-bottom: 3rem;
	border-bottom: 1px dashed $black;

	img {
		max-width: 200px;
		height: 100%;
		margin-right: 2rem;
	}

	.text {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.name {
		margin-bottom: 0;
		font-weight: bold;
		font-size: 2.5rem;
	}

	.job-title {
		margin-bottom: 2.5rem;
	}

	p {
		display: flex;
		align-items: center;
		margin-top: .5rem;
		margin-bottom: .5rem;
		font-size: 1.8rem;
		font-weight: normal;
	}

	.icon {
		margin-right: 1.5rem;
		color: $blue;
		width: 2rem;
		height: 2rem;
	}

	.phone, .email {
		word-break: break-all;
		color: $black;
		text-decoration: none;
	}

	@include media-breakpoint-down(s) {
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 5rem;

		img {
			max-width: 160px;
		}

		.text {
			margin-top: 1rem;
			padding-bottom: 1rem;
		}

		.name {
			font-size: 2.2rem;
		}

		p, .job-title {
			margin-bottom: .5rem;
			font-size: 1.6rem;
		}
	}
}

@mixin pwd-figcaption() {
	margin: 1rem;
	text-align: left;
	color: $black;
	font-size: 1.8rem;
}

@mixin cta-link-styles {
	display: flex;
	justify-content: center;

	svg {
		color: $blue;
	}

	a {
		@include link-with-inline-svg;
		box-shadow: 0 0 1.5rem rgba($black, .3);
		padding: 1.5rem 2.5rem;
		transition: .2s ease-in-out;
		font-size: 1.6rem;
		background: $white;
		letter-spacing: .75px;

		&:hover, &:focus, &:active {
			box-shadow: 0 0 1.5rem rgba($black, .5);
		}
	}
}

@mixin vertical-border-styles {
	width: 1px;
	border-left: 1px dashed $black;
	height: 100%;
	min-height: 1.5rem;
}

@mixin horizontal-border-styles {
	height: 1px;
	width: 100%;
	min-width: 1.5rem;
	border-bottom: 1px dashed $black;
}

@mixin vertical-border-ball-start {
	position: relative;

	&:before {
		content: url('/wp-content/themes/pot/assets/images/circle-blue.svg');
		width: 1.5rem;
		height: 1.5rem;
		display: block;
		top: -2px;
		position: absolute;
		left: calc(-.75rem - 0);
	}
}

@mixin vertical-border-ball-end {
	position: relative;

	&:after {
		content: url('/wp-content/themes/pot/assets/images/circle-blue.svg');
		width: 1.5rem;
		height: 1.5rem;
		display: block;
		bottom: -2px;
		position: absolute;
		left: calc(-.75rem - 0);
	}
}

@mixin horizontal-border-ball-start {
	position: relative;

	&:before {
		content: url('/wp-content/themes/pot/assets/images/circle-blue.svg');
		width: 1.5rem;
		height: 1.5rem;
		display: block;
		left: -2px;
		position: absolute;
		top: calc(-.75rem - 1px);
	}
}

@mixin horizontal-border-ball-end {
	position: relative;

	&:before {
		content: url('/wp-content/themes/pot/assets/images/circle-blue.svg');
		width: 1.5rem;
		height: 1.5rem;
		display: block;
		right: -2px;
		position: absolute;
		top: calc(-.75rem - 1px);
	}
}

@mixin meta-styles {
	text-transform: uppercase;
	font-size: 1.4rem;
	margin-bottom: 1rem;
	letter-spacing: .75px;

	.taxes {
		border-left: 4px solid $green_bright;
		padding-left: 1rem;
		margin-left: 0.5rem;

		&.aboard {
			border-left: 4px solid $blue_2;
		}
	}
}
