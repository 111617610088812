p,
blockquote,
.alignnone,
.aligncenter,
.alignleft,
.alignright,
.textwidget,
[class*="wp-block"] {
	margin-top: 0;
	margin-bottom: floor($base-font-size * 1.5) * 1px;
	margin-bottom: ((floor($base-font-size * 1.5)) / $base-reset-target-size) * 1rem;
}

blockquote {
	margin-left: 0;
	margin-right: 0;
	color: $theme-color-1;
	font-style: italic;
	font-weight: bold;
	@include pwd-fontsize($base-font-size + 8);

	p {
		display: inline;
		margin-bottom: 0;
	}

	&:before {
		content: "“";
	}

	&:after {
		content: "”";
	}
}

a {
	word-wrap: break-word; // Legacy property for browsers that don't support "overflow-wrap"
	overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	color: $base-text-color;
	font-weight: $heading-font-weight;
	font-style: $heading-font-style;
	line-height: $heading-line-height;
}

h1 {
	margin-bottom: 1em;
	color: $black;
	@include pwd-fontsize(nth($heading-font-sizes-xs, 1));
}

h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 12px;
	margin-bottom: (12 / $base-reset-target-size) * 1rem;
}

* + {
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 25px;
		margin-top: (25 / $base-reset-target-size) * 1rem;
	}
}

h2 {
	@include pwd-fontsize(nth($heading-font-sizes-xs, 2));
}

h3 {
	@include pwd-fontsize(nth($heading-font-sizes-xs, 3));
}

h4 {
	@include pwd-fontsize(nth($heading-font-sizes-xs, 4));
}

h5 {
	@include pwd-fontsize(nth($heading-font-sizes-xs, 5));
}

h6 {
	@include pwd-fontsize(nth($heading-font-sizes-xs, 6));
}

// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

figure {
	max-width: 100%;
}

// Media alignment

.alignnone {
	margin-left: 0;
	margin-right: 0;
	max-width: 100%;
	height: auto;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Only float if not on an extra small device

@include media-breakpoint-up(sm) {
	.alignleft {
		float: left;
		margin-right: $grid-gutter-width-base / 2;
	}

	.alignright {
		float: right;
		margin-left: $grid-gutter-width-base / 2;
	}
}

// Captions

.wp-caption {
	color: lighten($base-text-color, 40%);
	font-size: 0.9em;
}

// Text meant only for screen readers

.screen-reader-text {
	@extend .sr-only;
	@extend .sr-only-focusable;
}

// Custom contents

.pot-content-quote {
	font-style: italic;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-style: italic;
		font-weight: 800;
		text-transform: uppercase;
	}
}

.pot-link-list {
	ul {
		margin-left: 0;
		padding-left: 0;
		list-style-type: none;
	}

	li {
		position: relative;
		min-height: 1em;
		padding-left: 10px;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			color: $base-link-color;
			content: "›";
			speak: none;
		}

		&:not(:last-child) {
			margin-bottom: 4px;
		}
	}
}

.pot-bg-5 .pot-link-list li:before {
	color: $theme-color-6;
}

// Default page view

.pot-sidebar-content {
	ul,
	ol {
		margin-left: 0;
		padding-left: 20px;
	}
}

// Contacts template

.pot-contacts-list-1 {
	[class*="col-"] {
		text-align: center;
	}

	h3 {
		@include pwd-heading-style(6, font-size);
	}
}

.pot-contacts-list-1-item {
	h3,
	h4 {
		margin-top: 0;
		margin-bottom: 0.1em;
	}

	img {
		margin-bottom: 15px;
	}
}

ul.pot-contacts-detail-list {
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;

	li {
		margin-bottom: 5px;
	}
}

.pot-sidebar-contacts h4 {
	margin-bottom: 0.5em;
}

// Embedded content

.pot-twitter-timeline {
	margin-bottom: 1em;
	max-width: 360px;
}

@media screen {
	// Custom contents

	.pot-content-quote,
	.pot-content-quote * {
		color: $theme-color-1;
	}

	.pot-bg-5 {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 30px;
			color: #ffffff;
		}
	}
}

@media only print, screen and (min-width:#{map-get($grid-breakpoints, sm)}) {
}

@media only print, screen and (min-width:#{map-get($grid-breakpoints, md)}) {
	// Custom contents

	.pot-content-quote {
		h1,
		h2,
		h3 {
			margin-bottom: 1.1em;
			@include pwd-fontsize(40);
		}
	}
}

@media only print, screen and (min-width:#{map-get($grid-breakpoints, lg)}) {
	// Heading sizes for large screens

	@each $heading in $heading-font-sizes {
		$level: index($heading-font-sizes, $heading);

		h#{$level} {
			@include pwd-heading-style($level, font-size);
		}
	}

	// Contacts template

	.pot-contacts-list-1 {
		h2 {
			margin-bottom: 1em;
		}
	}
}
