/**
 *	Navigation, mobile
 */

 .nav-container-desktop, .header__top-row, .search-link {
	display: none;
}

body.nav--active {
	overflow: hidden;
}

.header {
	border-bottom: 1px dashed $black;

	&__inner {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		padding: 1rem;
	}

	&__quick-links-left, &__quick-links-right {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		a {
			padding: 1rem 2rem 1rem 0;

			svg {
				display: none;
			}
		}
	}
}

// hamburger button
#toggle-nav-menu {
	transition: none;
	z-index: 3;
	width: 4rem;
	height: 4rem;
	padding: 0;
	border: none;
	color: $black;
	background-color: rgba($white, .5);

	&:hover, &:focus, &:active {
		outline: 1px dashed $black;
		cursor: pointer;
	}

	img, svg {
		width: 100%;
		pointer-events: none;
	}

	.close {
		width: 0;
		margin: 0;
		display: none;
	}

	.hamburger {
		width: 100%;
		height: 100%;
	}

	&.nav-open {
		position: fixed;
		top: 1.5rem;
		right: 2rem;

		.close {
			height: 100%;
			width: 100%;
			display: block;
		}

		.hamburger {
			display: none;
		}
	}

	&.no-js {
		display: none;
	}
}

// lang
.langs-mobile {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 0;
	margin: 0;

	li a {
		padding: 1rem 2rem 1rem 0;
	}
}

// search link
.nav-container-mobile .search-link {
	display: block;
	margin: auto;
	display: flex;
	width: fit-content;

	svg {
		width: 2.5rem;
		height: 2.5rem;
	}
}

// nav container div
.nav-container-mobile {
	position: fixed;
	min-height: 100vh;
	margin: 0;
	width: 70vw;
	display: none;
	pointer-events: none;
	top: 0;
	right: 0;
	z-index: 2;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	height: 100%;
	background: $white;
	box-shadow: 0 0 1.5rem rgb($black, .5);
	padding: 6rem 2rem 2rem;

	@include media-breakpoint-down (s) {
		width: 100%;
	}

	&--visible {
		display: block;
		pointer-events: all;
	}

	.nav-top-area {
		width: 100%;
		padding-bottom: 5rem;
		position: fixed;
		left: 0;
		top: 0;
		background: $white;
		box-shadow: 0 .5rem 1rem $white;
		z-index: 1;

		&.no-js {
			display: none;
		}

		@include media-breakpoint-up (sm) {
			width: 70vw;
			right: 0;
			left: initial;
		}
	}

	&--no-js {
		display: block;
		position: initial;
		padding: 0 !important;
		box-shadow: none;
		min-height: auto;
		width: 100%;
		pointer-events: all;

		.nav-main__list {
			display: flex;
			flex-wrap: wrap;
		}

		.nav-main__item--has-children {
			border: none;

			a {
				padding: .5rem 1rem .5rem 0 !important;
			}
		}
	}
}

// with admin bar
.admin-bar {
	.nav-top-area {
		top: 46px;

		@include media-breakpoint-up (md) {
			top: 32px;
		}
	}

	.nav-container-mobile {
		padding-top: calc( 5rem + 46px );

		@include media-breakpoint-up (md) {
			padding-top: calc( 5rem + 32px );
		}
	}
}

// wp nav menu
.nav-main {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;

	&__list {
		& > .nav-main__item { // first level items
			padding: 0;

			a {
				padding: 1.5rem 0;
			}
		}
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid rgba($black, .15);

		a {
			padding: .5rem 0;
			flex-grow: 1;
			font-weight: bold;
			text-decoration: none;
			color: $black;

			&:hover, &:focus, &:active {
				text-decoration: underline;
			}
		}

		&.nav-main--active > a {
			text-decoration: underline;
		}
	}

	.toggle-submenu {
		display: flex;
		background: none;
		padding: 0;
		width: 3.5rem;
		height: 3.5rem;
		border: none;

		&:hover, &:focus, &:active {
			cursor: pointer;
			outline: 1px dashed $black;
		}

		svg {
			width: 100%;
			height: 100%;
			transform: translateX(6px);
			transition: .2s ease-in-out;
			pointer-events: none;
		}

		&--opened {
			svg {
				transform: translateX(-6px) rotate(180deg);
			}
		}
	}

	&__item--has-children {
		flex-wrap: wrap;

		// submenus hidden by default, visible with --visible modifier
		.nav-main__sub-menu {
			display: none;
			margin: 0;
			padding: 0 0 0 2rem;
			overflow: hidden;
			order: 1;
			width: 100%;

			.nav-main__item:last-child {
				border-bottom: none;
			}

			&--visible {
				display: block;
				overflow: visible;
			}
		}
	}
}
